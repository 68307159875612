<template>
    <div>
        <el-container>
            <el-main>
            <!--
                <el-button-group>
                    <el-button type="" size="mini" @click="syslistquery">查询</el-button>
                </el-button-group>
                -->
                <vxe-form size="mini">
                    <vxe-form-item span="3" title="日期" field="name" :item-render="{}">
                        <vxe-input v-model="p.dates" type="date" size="mini" placeholder="日期"></vxe-input>
                    </vxe-form-item>
                    <vxe-form-item span="3" title="~" field="name" :item-render="{}">
                        <vxe-input v-model="p.datee" type="date" size="mini" placeholder="日期"></vxe-input>
                    </vxe-form-item>
                    <vxe-form-item title="单据号" field="code" :item-render="{}" style="width:60px;">
                        <vxe-input v-model="p.code" size="mini" placeholder="单据号"></vxe-input>
                    </vxe-form-item>
                    <vxe-form-item>
                         <el-button type="" size="mini" @click="syslistquery">查询</el-button>
                    </vxe-form-item>
                    
                   
                </vxe-form>
                 <div style="width: 50%;float:left" >
                    <vxe-table ref='multipleTable' @cell-click="handleSelectionChange" :cell-style="rowStyle" show-overflow highlight-current-row border show-header-overflow highlight-hover-row size="mini" :data="rs" height="550" >
                        <vxe-table-column field="id" v-if="false" title="采购单ID" width="100"></vxe-table-column>
                        <vxe-table-column field="remak" title="转档" width="50">
                        
                            <template #default="{row, rowIndex }">
                                <el-tag size="medium" :type="row.remak === '0' ? 'info' :(row.remak==='1' ? 'success':(row.remak==='2' ? 'warning':'danger'))" >
                                    {{row.remak == '0'?"未转":(row.remak =='1'?"已转":(row.remak =='2'?"接收":"异常"))}}
                                </el-tag>
                              <!--<v-icon :color="row.remak==='0'?'success':'error'">fiber_manual_record</v-icon>-->
                               <!-- {{row.remak == '0'?"未转":(row.remak =='1'?"已转":"已接收")}}-->
                            </template>
                        
                          
                        </vxe-table-column>
                        <vxe-table-column styel="font-size:10px;" size="mini" field="code" title="采购单号" width="130"></vxe-table-column>
                        <vxe-table-column field="idpartner" v-if="false" title="供应商ID" width="180"></vxe-table-column>
                        <vxe-table-column field="partnerabbname" title="供应商" width="180"></vxe-table-column>
                        <vxe-table-column field="voucherdate" title="单据日期" width="100"></vxe-table-column>
                        <vxe-table-column field="acceptdate" title="交期" width="100"></vxe-table-column>
                        <vxe-table-column field="demo"  title="异常信息"  width="150"></vxe-table-column>
                        <vxe-table-column fixed="right" width="80" title="操作" >
                            <template #default="{row, rowIndex }">
                                <a @click="syslistsentone(row)">传输订单</a>
                            </template>
                        </vxe-table-column>
                    </vxe-table>
                    <vxe-pager :align="'left'" background :current-page.sync="p1.page.current" :page-size.sync="p1.page.size" :total="p1.page.total" @page-change="pagechange" :page-sizes=[100,500,1000,10000] :layouts="['PrevJump', 'PrevPage', 'JumpNumber', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']">
                    </vxe-pager>
                </div>
                <div style="width: 50%;float:left" >
                    <vxe-table :cell-style="rowStyle" show-overflow highlight-current-row border show-header-overflow highlight-hover-row size="mini" :data="rs1" height="550">
                        
                        <vxe-table-column field="nos"  title="序号" width="55"></vxe-table-column>
                        <vxe-table-column field="id" v-if="false" title="明细单id" width="130"></vxe-table-column>
                        <vxe-table-column field="idpurchaseorderdto" v-if="false" title="主档id" width="200"></vxe-table-column>
                        <vxe-table-column field="pubuserdefnvc3"  title="发货通知" width="200"></vxe-table-column>
                        <vxe-table-column field="invoiccode"  title="最新发票号" width="120"></vxe-table-column>
                        <vxe-table-column field="code" title="存货编码" width="90"></vxe-table-column>
                        <vxe-table-column field="name" title="存货名称" width="150"></vxe-table-column>
                        <vxe-table-column field="specification" title="规格型号" width="150"></vxe-table-column>
                        <vxe-table-column field="idunit" v-if="false" title="单位id" width="150"></vxe-table-column>
                        <vxe-table-column field="unitname"  title="单位" width="48"></vxe-table-column>
                        <vxe-table-column field="quantity" title="数量" width="50"></vxe-table-column>
                        <vxe-table-column field="basetaxprice"  title="含税单价" width="100"></vxe-table-column>
                        <vxe-table-column field="origtaxamount"  title="含税金额" width="100"></vxe-table-column>
                        <vxe-table-column field="origdiscountprice"  title="单价(净)" width="100"></vxe-table-column>
                        <vxe-table-column field="discountAmount"  title="金额(净)" width="100"></vxe-table-column>
                        <vxe-table-column field="priuserdefnvc2"  title="反馈备注" width="200"></vxe-table-column>
                        <vxe-table-column field="pubuserdefnvc4"  title="备注" width="200"></vxe-table-column>

                    </vxe-table>
                    
                    <vxe-pager :align="'left'" background :current-page.sync="p2.page.current" :page-size.sync="p2.page.size" :total="p2.page.total" @page-change="pagechange" :page-sizes=[100,500,1000,10000] :layouts="['PrevJump', 'PrevPage', 'JumpNumber', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']">
                    </vxe-pager>
                    
                </div>
            </el-main>
        </el-container>
    </div>
</template>
<script>
    import ax from "@/js/ax.js";
    import Vue from "vue";

    export default {
        name: "orderlist1",
        extends: ax.control1(),
        data() {
            return {
                p: { code: "",
                     dates:"",
                     datee:"" 
                    },
                rs: [],
                rs1:[],
                p1: {
                    page: {
                        size: 50,
                        current: 1,
                        total: 0
                    }
                },
                 p2: {
                    page: {
                        size: 50,
                        current: 1,
                        total: 0
                    }
                },
                ps: { 
                    ids:""
                },
                multipleTable: [],
            }
        },
        mounted(){
            this.syslistquery()
            },
        methods: {
            pushItem(item) {},
            syslistquery() {
                let _this = this;
                if(this.p.dates==''){
                    this.p.dates='00000000';
                }
                if(this.p.datee==''){
                    this.p.datee='zzzzzzzz';
                }
                let pp = {
                    details: {
                        code: this.p.code,
                        dates: this.p.dates,
                        datee: this.p.datee
                    },
                    page: this.p1.page
                }
                // let id;
                ax.base.post("/gd/querylistgund", pp).then(data => {
                    console.log(data.data);
                    this.rs = data.data.records;
                    // id =  data.data.records[0].id;
                    this.p1.page.total = data.data.total;
                })

                //  let pps = {
                //     details: {
                //         ids: id
                //     },
                //     page: this.p1.page
                // }
                // ax.base.post("/gd/querylistd", pps).then(data => {
                //     this.rs1 = data.data.records;
                //     this.p2.page.total = data.data.total;
                // })
            },
            pagechange({ currentPage, pageSize }) {
                this.p1.page.current = currentPage;
                this.p1.page.size = pageSize;
                this.syslistquery();
               // this.handleSelectionChange(null);
            },
            handleSelectionChange(val) {
                let id = val.row.id;
                console.log(id);
                console.log(this.p1.page);
                let pps = {
                    details: {
                        ids: id
                    },
                    page: this.p2.page
                }
                ax.base.post("/gd/querylistd", pps).then(data => {
                    this.rs1 = data.data.records;
                    console.log(data.data);
                    this.p2.page.total = data.data.total;
                })

                //console.log(id);

                // this.multipleTable = val;               //  this.multipleTable 选中的值
                // console.log(val);
            },  
            syslistsentone(row) {
                this.$XModal.confirm('您确定要传输资料吗？').then(type => {
                    // this.$XModal.message({ content: `点击了 ${type}` })
                    if (type == 'confirm') {
                        let _this = this;
                        let pp = {
                            "id": row.id
                        }
                    let remak1 = row.remak;
                    // if(remak1==='1'||remak1==='2'){
                    //     _this.$XModal.message({ message: "采购单"+row.code+"已转档或已接收不可重新转档！",status: 'error' })
                    //     return;
                    // }
                        ax.base.post("/gd/syslistsentone", pp).then(data => {
                            console.log('00900900990909+data:+'  +data+'---------')
                            _this.syslistquery();
                            _this.$XModal.message({ message: "资料传输成功!", status: 'success' })
                        }).catch(data => {
                        _this.$XModal.message({ message: "资料传输失败"+data.data, status: 'error' })
                    })
                    }
                })

            },
        },
       
    }
</script>